import React, { useEffect } from "react";
import "./_Insumos.scss";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { Row, Table } from "react-bootstrap";
import Insumo from "./Insumo";

function ListaInsumos(props) {
	const firebaseApp = useFirebaseApp();

	const [insumos, loading_insumos, error_insumos] = useCollection(
		firebaseApp
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_insumo")
			.doc(props.id_categoria)
			.collection("insumos")
	);

	useEffect(() => {}, [loading_insumos]);

	return (
		!loading_insumos &&
		insumos.docs.map((insumo) => {
			return (
				<Insumo
					key={insumo.id}
					nombre_categoria={props.nombre_categoria}
					id_categoria={props.id_categoria}
					id_insumo={insumo.id}
				/>
			);
		})
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(ListaInsumos);
