import React, { useEffect } from 'react';
import '../insumos/_Insumos.scss';
import { useSelector } from 'react-redux';
import { Badge, Col, Container, Row, Button, Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import { useCollection } from 'react-firebase-hooks/firestore';

function IndexProveedores(props) {
	const firebase = useFirebaseApp();

	const usuario = useSelector((state) => state.user);

	const [proveedores, loading_proveedores, error_proveedores] = useCollection(
		firebase
			.firestore()
			.collection('usuarios')
			.doc(usuario.id)
			.collection('establecimientos')
			.doc(usuario.establecimiento)
			.collection('proveedores')
	);

	useEffect(() => {}, [loading_proveedores]);

	let location = useLocation();

	return (
		<Container className='containerListaInsumos'>
			<Row>
				<p className='titleLista'>PROVEEDORES</p>
			</Row>
			<Row>
				<Col>
					<p className='subtitleLista'>Proveedores de insumos</p>
				</Col>
				<Col style={{ textAlign: 'right' }}>
					<Button as={Link} to={`${location.pathname}/nuevo`}>
						Nuevo Proveedor
					</Button>
				</Col>
			</Row>
			<Table>
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Web</th>
						<th>Dirección</th>
						<th>Email</th>
						<th>Teléfono</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{!loading_proveedores &&
						proveedores.docs.map((proveedor) => {
							return (
								<tr key={proveedor.id} style={{ width: '10rem' }}>
									<td>{proveedor.data().nombre}</td>
									<td>{proveedor.data().web}</td>
									<td>{proveedor.data().direccion}</td>
									<td>{proveedor.data().email}</td>
									<td>{proveedor.data().telefono}</td>
									<td>
										<Link to={`proveedor/${proveedor.id}`}>
											<Button disabled size='sm'>
												Editar
											</Button>
										</Link>
									</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</Container>
	);
}

export default IndexProveedores;
