import React, { useEffect } from "react";
import "./_Recetas.scss";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import Receta from "./Receta";

function ListaRecetas(props) {
	const firebaseApp = useFirebaseApp();

	const [recetas, loading_recetas, error_recetas] = useCollection(
		firebaseApp
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_recetas_base")
			.doc(props.id_categoria)
			.collection("recetas_base")
	);

	useEffect(() => {
		console.log("recetas", recetas);
	}, [loading_recetas]);

	return (
		<Row>
			{!loading_recetas &&
				recetas.docs.map((receta) => {
					console.log(receta);
					return (
						<Receta
							key={receta.id}
							id_categoria={props.id_categoria}
							id_receta={receta.id}
						/>
					);
				})}
		</Row>
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(ListaRecetas);
