import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import { Form, Button, Divider } from "react-bootstrap";

import { connect } from "react-redux";
import { useFirebaseApp } from "reactfire";

import { useHistory } from "react-router-dom";

import { useDocument, useCollection } from "react-firebase-hooks/firestore";

const initialValues = {
  nombre: "",
  categoria: "",
  cantidad: 0,
  medida: "",
  precio: "",
  descripcion: "",
  cantidad_inicial: 0,
  equivalencia: "",
  proveedor: "",
  nombre_especifico: "",
  fecha_precio: 0,
};

function NuevoInsumo(props) {
	const firebase = useFirebaseApp();
	const history = useHistory();

	const [
		categorias_insumos,
		loading_categorias_insumos,
		error_categorias_insumos,
	] = useCollection(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_insumo")
	);

	const [proveedores, loading_proveedores, error_proveedores] = useCollection(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("proveedores")
	);

	return (
		<Container>
			<br />

			<h3 className="titleLista">Nuevo insumo</h3>

			<hr />
			<Formik
				initialValues={initialValues}
				validate={(values) => {
					//TODO validar campos
					// const errors = {};
					// if (!values.nombre) {
					//   errors.nombre = "Requerido";
					// } else if (!values.apellido) {
					//   errors.apellido = "Requerido";
					// }
					// return errors;
				}}
				onSubmit={async (values, { setSubmitting }) => {
					// await new Promise((r) => setTimeout(r, 500));
					// alert(JSON.stringify(values, null, 2));

          let data = {
            nombre: values.nombre,
            cantidad: values.cantidad,
            medida: values.medida,
            precio: values.precio,
            cantidad_inicial: values.cantidad_inicial,
            equivalencia: values.equivalencia,
            proveedor: values.proveedor,
            fecha_precio: values.fecha_precio,
            nombre_especifico: values.nombre_especifico,
          };

					setSubmitting(false);

					// TODO crear función para armar los paths de forma más cómoda
					try {
						let updateRef = await firebase
							.firestore()
							.collection("usuarios")
							.doc(props.usuario.id)
							.collection("establecimientos")
							.doc(props.usuario.establecimiento)
							.collection("categorias_insumo");

						let nueva;

						if (values.categoria == "new") {
							nueva = await updateRef.add({ nombre: values.nueva_categoria });
							console.log("nueva categoria: ", nueva.id);
						}

						await updateRef
							.doc(nueva ? nueva.id : values.categoria)
							.collection("insumos")
							.add(data);

						// TODO: agregar mensaje de éxito cuando se hace submit
						console.log("Nueva insumo agregado");
						history.goBack();
					} catch (error) {
						// TODO: mostrar mensaje de error
						console.error(error.message);
					}

					setSubmitting(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form onSubmit={handleSubmit}>
						<Row>
							<Col xs={6}>
								<Form.Label htmlFor="nombre">Nombre</Form.Label>
								<Form.Control
									value={values.nombre}
									onChange={handleChange}
									onBlur={handleBlur}
									id="nombre"
									name="nombre"
									placeholder="Nombre del insumo"
								/>
							</Col>
							<Col xs={6}>
								<Form.Label htmlFor="categoria">Categoría</Form.Label>
								<Form.Control
									id="categoria"
									name="categoria"
									placeholder="Categoria"
									as="select"
									value={values.categoria}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									<option>Seleccione categoría</option>
									{!loading_categorias_insumos &&
										categorias_insumos.docs.map((cat) => (
											<option key={cat.id} value={cat.id}>
												{cat.data().nombre}
											</option>
										))}
									<option disabled>-----------------</option>
									<option value="new" key="none">
										Agregar nueva categoría
									</option>
								</Form.Control>

								{values.categoria == "new" && (
									<Form.Control
										id="nueva_categoria"
										name="nueva_categoria"
										placeholder="Nombre de la nueva categoría"
										value={values.nueva_categoria}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								)}
							</Col>
						</Row>
						<br />
						<Row>
							<Col xs={6}>
								<Form.Label htmlFor="cantidad">
									Cantidad{" "}
									<span style={{ fontWeight: "bold", fontSize: "small" }}>
										que viene directo del proveedor
									</span>
								</Form.Label>
								<Form.Control
									id="cantidad"
									name="cantidad"
									placeholder="Cantidad"
									type="number"
									value={values.cantidad}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</Col>
							<Col xs={6}>
								<Form.Label htmlFor="medida">Unidad de medida</Form.Label>
								<Form.Control
									id="medida"
									name="medida"
									placeholder="Medida"
									as="select"
									value={values.medida}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									<option>Seleccionar...</option>
									<option value="mm3">Milimetros Cúbicos - mm3</option>
									<option value="cm3">Centimetros Cúbicos - cm3</option>
									<option value="m3">Metros Cúbicos - m3</option>
									<option value="mm">Milimentros - mm</option>
									<option value="cm">Centímetros - cm</option>
									<option value="m">Metros - m</option>
									<option value="mg">Miligramos - mg</option>
									<option value="gr">Gramos - gr</option>
									<option value="kg">Kilos - kg</option>
								</Form.Control>
							</Col>
						</Row>
						<br />
						<Row>
							<Col xs={6}>
								<Form.Label htmlFor="precio">Precio</Form.Label>
								<Form.Control
									id="precio"
									name="precio"
									placeholder="Precio"
									type="number"
									value={values.precio}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</Col>
						</Row>

						<br />
						<hr />
						<h5 className="titleLista">Equivalencias</h5>
						<p className="subtitleLista">
							Acá vas a calcular las equivalencias de ese insumo. Por ejemplo: 1
							pilon de manteca > 5 kilos 1 maple de huevos > 30 unidades
						</p>
						<hr />

            <Row>
              <Col>
                <Form.Label htmlFor="cantidad_inicial">Cantidad </Form.Label>
                <Form.Control
                  id="cantidad_inicial"
                  name="cantidad_inicial"
                  placeholder="Cantidad"
                  value={values.cantidad_inicial}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col>
                <Form.Label htmlFor="precio">Equivale a </Form.Label>
                <Form.Control
                  id="equivalencia"
                  name="equivalencia"
                  placeholder="Equivalencia"
                  value={values.equivalencia}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
            <hr />
            <h5 className="titleLista">Proveedor</h5>
            <p className="subtitleLista">
              Aca vas a anotar los datos de donde proviene este insumo.
            </p>
            <hr />
            <Row>
              <Col xs={6}>
                <Form.Label htmlFor="proveedor">Proveedor</Form.Label>
                <Form.Control
                  id="proveedor"
                  name="proveedor"
                  placeholder="Nombre del proveedor"
                  value={values.proveedor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option>Seleccione Proveedor</option>
                  <option disabled>-----------------</option>
                  {!loading_proveedores &&
                  proveedores.docs.map((prov) => (
                    <option key={prov.id} value={prov.id}>
                      {prov.data().nombre}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Label htmlFor="nombre_especifico">
                  Nombre especifico del producto
                </Form.Label>
                <Form.Control
                  id="nombre_especifico"
                  name="nombre_especifico"
                  placeholder="Nombre del producto"
                  value={values.nombre_especifico}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col xs={6}>
                <Form.Label htmlFor="fecha_precio">Fecha de precio</Form.Label>
                <Form.Control
                  id="fecha_precio"
                  name="fecha_precio"
                  placeholder="DD/MM/AAAA"
                  type="date"
                  value={values.fecha_precio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
            <br />
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              Guardar
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

// export default NuevoInsumo;

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(NuevoInsumo);
