import { Formik } from "formik";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import LogoSVG from "../../assets/logo.svg";

import "../primera-configuracion/_ingreso-datos.scss";
import Button from "react-bootstrap/Button";
import { Col, Form, Row, Image } from "react-bootstrap";
import { useFirebaseApp } from "reactfire";

function NuevoEstablecimiento() {
  const firebase = useFirebaseApp();

  let storageRef = firebase.storage().ref("/imagenes-establecimientos");
  let user = firebase.auth().currentUser;
  let db = firebase.firestore();

  return (
    <div className="contenedorInicio container-xxl">
      <div className="card cardLogin">
        <img src={LogoSVG} className="logoFormLogin" />
        <div className="card-body">
          <Formik
            initialValues={{ establecimiento: "", file: {} }}
            validate={(values) => {
              //validar ingreso de contraseña
              const errors = {};
              if (!values.establecimiento) {
                errors.establecimiento = "Complete los datos requeridos";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                let estabRef = await db
                  .collection("usuarios")
                  .doc(user.uid)
                  .collection("establecimientos")
                  .add({ nombre: values.establecimiento });
              } catch (error) {
                console.error("Error al pedir datos ");
              }

              //! Ingreso de imagen cancelada por el momento

              // let refImg = storageRef.child(estabRef.id);

              // try {
              //   await refImg.put(values.file);
              //   console.log("imagen subida correctamente");
              // } catch (error) {

              //   console.error("error subiendo la imagen: ", error);
              // }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              submitCount,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) =>
              submitCount > 0 ? (
                <Redirect to="/ingreso-establecimiento" />
              ) : (
                <Form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <Row className="justify-content-md-center">
                      <p>
                        <b>CREAR NUEVO ESTABLECIMIENTO</b>
                      </p>
                    </Row>
                    <Row className="justify-content-md-center pt-3">
                      {/* <Col sm={2}>
                        <Image src={LogoSVG} rounded />
                      </Col> */}
                    </Row>
                    <Row className="justify-content-md-center pt-3">
                      {/* <Form.Control
                        type="file"
                        id="file"
                        name="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                        }}
                      /> */}
                    </Row>
                    <Row className="justify-content-md-center pt-3">
                      <Col sm={2}>
                        <Image src={LogoSVG} rounded />
                      </Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputLogin"
                          placeholder="Tu establecimiento..."
                          type="text"
                          name="establecimiento"
                          onChange={handleChange}
                        />
                        <Form.Text>
                          {errors.establecimiento &&
                            touched.establecimiento &&
                            errors.establecimiento}
                        </Form.Text>
                      </Col>
                    </Row>

                    <Row className="justify-content-md-center pt-4 pl-2 pr-2">
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        CREAR NUEVO
                      </Button>
                    </Row>

                    <Row className="pt-3 justify-content-center">
                      <Link to="/ingreso-establecimiento">Volver</Link>
                    </Row>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default NuevoEstablecimiento;
