import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	InputGroup,
	Modal,
} from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useFirebaseApp } from 'reactfire';
import './_Recetas.scss';

const ModalAgregarInsumo = (props) => {
	const firebaseApp = useFirebaseApp();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [insumos, loading_insumos, error_carga_insumos] = useCollection(
		firebaseApp
			.firestore()
			.collection(
				`usuarios/${props.id}/establecimientos/${props.establecimiento}/categorias_insumo/`
			)
	);

	const [options, setOptions] = useState([]);
	const [datosInsumos, setDatosInsumos] = useState([]);

	const initInsumos = () => {
		let insumosArray = [];

		insumos.docs.forEach((insumo) => {
			return insumosArray.push({
				id: insumo.id,
				nombre: insumo.data().nombre,
			});
		});

		let lista = [];
		let datos = [];

		!!insumosArray &&
			insumosArray.forEach((insumo) => {
				let ref = firebaseApp
					.firestore()
					.collection(
						`usuarios/${props.id}/establecimientos/${props.establecimiento}/categorias_insumo/${insumo.id}/insumos`
					);

				ref.get().then((result) => {
					lista.push(
						<option
							key={insumo.id}
							style={{ fontWeight: 'bold' }}
							value={insumo.id}
							disabled
						>
							{insumo.nombre}
						</option>
					);
					result.docs.map((doc) => {
						datos.push({ id: doc.id, insumo: doc.data() });
						return lista.push(
							<option
								key={doc.id}
								value={JSON.stringify({
									categoria: {
										id: insumo.id,
										medida: insumo.medida,
										nombre: insumo.nombre,
									},
									insumo: doc.id,
								})}
							>
								{doc.data().nombre}
							</option>
						);
					});
				});
			});

		setOptions(lista);
		setDatosInsumos(datos);
	};

	const cantidadInsumo = (insumo) => {
		let dato = datosInsumos.find((element) => element.id === insumo);

		return dato.insumo.medida;
	};

	const parseInsumo = (insumo) => {
		let dato = datosInsumos.find((element) => element.id === insumo);

		console.log(dato);

		return {
			nombre: dato.insumo.nombre,
			medida: dato.insumo.medida,
			id: dato.id,
		};
	};

	useEffect(() => {
		!loading_insumos && initInsumos();
	}, [loading_insumos]);

	return (
		<>
			<BsFillPlusCircleFill
				className='iconButtonAgregar'
				size={35}
				onClick={handleShow}
				title='Agregar insumos'
			/>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Agregar insumos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{ insumo: '', cantidad: '', categorias_insumo: '' }}
						onSubmit={(values) => {
							props.setFieldValue('insumosUsados', [
								...props.insumosUsados,
								{
									categoria_insumo: values.categorias_insumo,
									insumo: parseInsumo(values.insumo),
									cantidad: values.cantidad,
								},
							]);
							props.setInsumos([
								...props.insumos,
								{
									categoria_insumo: values.categorias_insumo,
									insumo: parseInsumo(values.insumo),
									cantidad: values.cantidad,
								},
							]);
							handleClose();
						}}
					>
						{({ values, handleSubmit, handleChange, setFieldValue }) => (
							<Form onSubmit={handleSubmit} id='formNuevoInsumoRecetaBase'>
								<Form.Control
									name='insumo'
									onChange={(e) => {
										setFieldValue('insumo', JSON.parse(e.target.value).insumo);
										setFieldValue(
											'categorias_insumo',
											JSON.parse(e.target.value).categoria
										);
									}}
									as='select'
								>
									<option value=''>Seleccione una opcion</option>
									{options}
								</Form.Control>
								<br />
								<FormLabel htmlFor='cantidad'>Cantidad</FormLabel>
								<InputGroup>
									{!!values.insumo && (
										<InputGroup.Text>
											{cantidadInsumo(values.insumo)}
										</InputGroup.Text>
									)}
									<FormControl
										id='cantidad'
										name='cantidad'
										onChange={handleChange}
										type='number'
										placeholder='Cantidad'
									/>
								</InputGroup>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						variant='primary'
						form='formNuevoInsumoRecetaBase'
						type='submit'
					>
						Agregar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ModalAgregarInsumo;
