import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import "./_Insumos.scss";
import { Link } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirebaseApp } from "reactfire";
import { connect } from "react-redux";

function Insumo(props) {
	const firebase = useFirebaseApp();

	const [insumo, loading_insumo, error_insumo] = useDocument(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_insumo")
			.doc(props.id_categoria)
			.collection("insumos")
			.doc(props.id_insumo)
	);

	useEffect(() => {}, [loading_insumo]);

  return (
    !loading_insumo && (
      <tr as={Link} to="#" style={{ width: "10rem" }}>
        <td>{insumo.data().nombre}</td>
        <td>${insumo.data().precio}</td>
        <td>{insumo.data().cantidad}</td>
        <td>{insumo.data().fecha_precio}</td>
        <td>{props.nombre_categoria}</td>
        <td>
          <Link to={ `insumo/${props.id_categoria}/${insumo.id}` }>
            <Button size="sm">Editar</Button>
          </Link>
        </td>
      </tr>
    )
  );
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(Insumo);
