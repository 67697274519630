import React, { useEffect } from "react";
import "./_Productos.scss";
import { connect } from "react-redux";
import { Badge, Col, Container, Row, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";

import { updateCategoriasProductos } from "@redux/actions/userActions.js";
import ListaProductos from "./ListaProductos";

function IndexProductos(props) {
	const firebase = useFirebaseApp();

	const [
		categorias_productos,
		loading_categorias_productos,
		error_categorias_productos,
	] = useCollection(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_producto")
	);

	///usuarios/PgKtDyIAcNTX55cS4YAyimJ0Ucz2/establecimientos/7s87bk2sTLdPJIjAhpon/categorias_producto/0KL6rFz6YoYRopDs6uE6

	/*  const updateData = () => {
    const categoriasProductos = categorias_productos.docs.map((doc) => {
      return { [doc.id]: { nombre: doc.data().nombre } };
    });
    props.updateCategoriasProductos(
      categoriasProductos,
      props.usuario.establecimiento
    );
  };
*/
	useEffect(() => {}, [loading_categorias_productos]);

	let location = useLocation();

	return (
		<Container className="containerListaProductos">
			<Row>
				<p className="titleLista">Productos Terminados</p>
			</Row>
			<Row>
				<p className="subtitleLista">Productos finales: Los que se venden</p>
			</Row>
			<Row>
				<Col></Col>
				<Col style={{ textAlign: "right" }}>
					<Button as={Link} to={`${location.pathname}/nuevo`}>
						Nuevo producto
					</Button>
				</Col>
			</Row>
			{!loading_categorias_productos &&
				categorias_productos.docs.map((categoria) => {
					return (
						<div key={categoria.id}>
							<Row>
								<Col>
									<h5>
										<Badge variant="primary" className="badgePrimary">
											{categoria.data().nombre}
										</Badge>
									</h5>
								</Col>
							</Row>
							<ListaProductos id_categoria={categoria.id} />
						</div>
					);
				})}
		</Container>
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCategoriasProductos: (categorias_productos, establecimiento) =>
			dispatch(
				updateCategoriasProductos(categorias_productos, establecimiento)
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexProductos);
