import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './_Productos.scss';
import { Link } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useFirebaseApp } from 'reactfire';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function getPic(doc, firebase, setPic) {
	if (!doc) {
		return;
	}

	const data = doc.data();

	if (!data.imagen) {
		setPic('https://via.placeholder.com/158x237/?text=The+cake+is+a+lie');
		return;
	}

	const ref = firebase.storage().refFromURL(data.imagen);

	ref
		.getDownloadURL()
		.then((url) => {
			setPic(url);
		})
		.catch((e) => {
			console.error('[getPic Error]', e);
			setPic('https://via.placeholder.com/158x237/?text=The+cake+is+a+lie');
		});
}

function Producto(props) {
	const firebase = useFirebaseApp();
	const [productPic, setPic] = useState();

	let location = useLocation();

	const [producto, loading_producto, error_producto] = useDocument(
		firebase
			.firestore()
			.collection('usuarios')
			.doc(props.usuario.id)
			.collection('establecimientos')
			.doc(props.usuario.establecimiento)
			.collection('categorias_producto')
			.doc(props.id_categoria)
			.collection('productos')
			.doc(props.id_producto)
	);

	useEffect(() => {
		getPic(producto, firebase, setPic);
	}, [producto]);

	return (
		!loading_producto && (
			<Card
				as={Link}
				to={`/panel-de-control/productos/${props.id_categoria}/${props.id_producto}`}
				style={{ width: '10rem' }}
			>
				<Card.Img variant='top' src={productPic} />
				<Card.ImgOverlay>
					<Card.Title className='titleCardProducto'>
						{producto.data().nombre}
					</Card.Title>
				</Card.ImgOverlay>
			</Card>
		)
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(Producto);
