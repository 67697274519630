//nombre de acciones

export const UPDATE_NOMBRE = 'UPDATE_NOMBRE';
export const UPDATE_ID = 'UPDATE_ID';
export const UPDATE_ESTABLECIMIENTO = 'UPDATE_ESTABLECIMIENTO';
export const UPDATE_CATEGORIAS_PRODUCTOS = 'UPDATE_CATEGORIAS_PRODUCTOS';
export const UPDATE_CATEGORIAS_RECETAS = 'UPDATE_CATEGORIAS_RECETAS';
export const UPDATE_CATEGORIAS_INSUMOS = 'UPDATE_CATEGORIAS_INSUMOS';
export const UPDATE_RECETA = 'UPDATE_RECETA';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

//creadores de acciones

export const updateReceta = (receta) => {
	return {
		type: UPDATE_RECETA,
		payload: {
			receta,
		},
	};
};

export const updateCategoriasInsumos = (categorias_insumo, establecimiento) => {
	return {
		type: UPDATE_CATEGORIAS_INSUMOS,
		payload: {
			categorias_insumo,
			establecimiento,
		},
	};
};

export const updateCategoriasRecetas = (categorias_receta, establecimiento) => {
	return {
		type: UPDATE_CATEGORIAS_RECETAS,
		payload: {
			categorias_receta,
			establecimiento,
		},
	};
};

export const updateCategoriasProductos = (
	categorias_productos,
	establecimiento
) => {
	return {
		type: UPDATE_CATEGORIAS_PRODUCTOS,
		payload: {
			categorias_productos,
			establecimiento,
		},
	};
};

export const updateNombre = (nombre, apellido) => {
	return {
		type: UPDATE_NOMBRE,
		payload: {
			nombre,
			apellido,
		},
	};
};

export const updateID = (id) => {
	return {
		type: UPDATE_ID,
		payload: {
			id,
		},
	};
};

export const updateEstablecimiento = (establecimiento) => {
	return {
		type: UPDATE_ESTABLECIMIENTO,
		payload: {
			establecimiento,
		},
	};
};

export const loginSucces = (user) => {
	return {
		type: LOGIN_SUCCESS,
		payload: {
			user,
		},
	};
};
export const logoutSuccess = () => {
	return {
		type: LOGOUT_SUCCESS,
		payload: {},
	};
};
