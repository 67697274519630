import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link, Redirect, Route, useHistory } from 'react-router-dom';
import LogoSVG from '../../assets/logo.svg';
import IconoUser from '../../assets/mujer.svg';
import IconoPass from '../../assets/corazon-fucsia.svg';

import './_login.scss';
import Button from 'react-bootstrap/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { register } from './helpers/api.helper';

function CrearCuenta() {
	const history = useHistory();

	return (
		<div className='contenedorInicio container-xxl'>
			<div className='card cardLogin'>
				<img src={LogoSVG} className='logoFormLogin' />
				<div className='card-body'>
					<Formik
						initialValues={{
							email: '',
							password: '',
							passwordDuplicado: '',
							name: '',
							tyc: false,
						}}
						validate={(values) => {
							//validar ingreso de contraseña
							const errors = {};
							if (
								!values.email ||
								!values.password ||
								!values.passwordDuplicado ||
								!values.tyc
							) {
								errors.email = 'Requerido';
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = 'Email inválido'; //manejar errores con booleanos que remarquen los inputs
							} else if (values.password !== values.passwordDuplicado) {
								errors.password = 'Las contraseñas no coinciden';
							}
							return errors;

							//manejar errores de demas variables
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								let res = await register({
									email: values.email,
									password: values.password,
								});

								if (res.code === 200) {
									history.push('/cuenta-creada');
									setSubmitting(false);
								} else {
									throw new Error(res);
								}
							} catch (error) {
								console.error('error al registrarse: ', error);
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Form onSubmit={handleSubmit}>
								<div ssName='form-group'>
									<Row className='justify-content-md-center pt-3'>
										<Col sm={1}>
											<Form.Label>
												<img className='iconoFormInput' src={IconoUser} />
											</Form.Label>
										</Col>
										<Col sm={10}>
											<Form.Control
												className='inputLogin'
												placeholder='Email'
												type='email'
												name='email'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
											/>
											<Form.Text>
												{errors.email && touched.email && errors.email}
											</Form.Text>
										</Col>
									</Row>
									<Row className='justify-content-md-center pt-3'>
										<Col sm={1}>
											<Form.Label>
												<img className='iconoFormInput' src={IconoPass} />
											</Form.Label>
										</Col>
										<Col sm={10}>
											<Form.Control
												className='inputLogin'
												placeholder='Contraseña'
												type='password'
												name='password'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.password}
											/>
										</Col>
									</Row>
									<Row className='justify-content-md-center pt-3'>
										<Col sm={1}>
											<Form.Label>
												<img className='iconoFormInput' src={IconoPass} />
											</Form.Label>
										</Col>
										<Col sm={10}>
											<Form.Control
												className='inputLogin'
												placeholder='Confirmar contraseña'
												type='password'
												name='passwordDuplicado'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.passwordDuplicado}
											/>
											<Form.Text>
												{errors.password && touched.password && errors.password}
											</Form.Text>
										</Col>
									</Row>
									<Row className='justify-content-center'>
										<Form.Check
											type='checkbox'
											label='Acepto los terminos y condiciones'
											name='tyc'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.tyc}
										/>
									</Row>
									<Row className='justify-content-md-center pt-4 pl-2 pr-2'>
										<Button
											block
											variant='primary'
											type='submit'
											disabled={isSubmitting}
										>
											Registrarme
										</Button>
									</Row>
									<Row className='pt-3 justify-content-center'>
										<Link to='/ingresar'>Ya tengo cuenta</Link>
									</Row>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default CrearCuenta;
