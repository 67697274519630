export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_PAQUETES";

export const addToast = (toast) => {
	return {
		type: ADD_TOAST,
		payload: {
			toast,
		},
	};
};

export const removeToast = () => {
	return {
		type: REMOVE_TOAST,
	};
};
