import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import {
	updateNombre,
	updateID,
	updateEstablecimiento,
} from '@redux/actions/userActions.js';

import CuentaCreada from '../login/CuentaCreada';
import Inicio from '../inicio/Inicio';
import IngresoDatos from '../primera-configuracion/IngresoDatos';
import IngresoEstablecimiento from '../primera-configuracion/IngresoEstablecimiento';
import NuevoEstablecimiento from '../establecimientos/NuevoEstablecimiento';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useEffect } from 'react';
import firebase from 'firebase';

function Authed(props) {
	const userRef = firebase
		.firestore()
		.collection('usuarios')
		.doc(props.user.uid);

	const [snapshot, loading, error] = useDocument(userRef);

	const updateDataUser = () => {
		props.updateNombre(snapshot.data().nombre, snapshot.data().apellido);
		props.updateID(props.user.uid);
		props.updateEstablecimiento(snapshot.data().establecimiento); //! ver snapshot.data().establecimiento
	};

	useEffect(() => {
		!loading && snapshot.data() !== undefined && updateDataUser();
	}, [snapshot]);

	return (
		!loading && (
			<Router>
				{!snapshot.data() || !snapshot.data().nombre ? (
					// No configuró el nombre
					<Switch>
						<Redirect exact from='/panel-de-control' to='/primer-ingreso' />
						<Route exact path='/primer-ingreso'>
							<IngresoDatos />
						</Route>
					</Switch>
				) : (
					// configuró el nombre; seleccionó (o no) el establecimiento
					<Switch>
						<Route
							exact
							path='/nuevo-establecimiento'
							component={NuevoEstablecimiento}
						/>
						<Route
							exact
							path='/ingreso-establecimiento'
							component={IngresoEstablecimiento}
						/>

						<Route path='/panel-de-control'>
							{!snapshot.data().establecimiento && (
								<Redirect exact to='/ingreso-establecimiento' />
							)}
							<Inicio />
						</Route>
					</Switch>
				)}
				<Switch>
					<Redirect exact from='/ingresar' to='/panel-de-control' />
					<Redirect exact from='/nueva-cuenta' to='/panel-de-control' />
					<Redirect exact from='/olvide-contrasena' to='/panel-de-control' />
					<Route exact path='/cuenta-creada'>
						<CuentaCreada />
					</Route>
				</Switch>
			</Router>
		)
	);
}

//Consume el estado global y lo guarda como prop.
// La prop es tomada por el componente y es de solo lectura

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

// consume las funciones dispatch para

const mapDispatchToProps = (dispatch) => {
	return {
		updateNombre: (nombre, apellido) =>
			dispatch(updateNombre(nombre, apellido)),
		updateID: (id) => dispatch(updateID(id)),
		updateEstablecimiento: (establecimiento) =>
			dispatch(updateEstablecimiento(establecimiento)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Authed);
