import { Formik } from "formik";
import { useState } from "react";
import { Button, Form, FormControl, FormLabel, Modal } from "react-bootstrap";
import { BsFillPlusCircleFill } from "react-icons/bs";

import "./_Recetas.scss";

const ModalAgregarPasoPreparacion = (props) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<BsFillPlusCircleFill
				className='iconButtonAgregar'
				size={35}
				onClick={handleShow}
				title='Agregar insumos'
			/>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Agregar paso a la preparacion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{ explicativo: "", tiempo: "" }}
						onSubmit={(values) => {
							if (!values.explicativo || !values.tiempo) {
								return alert("Debe rellenar los campos");
							}
							props.setFieldValue("preparacionReceta", [
								...props.preparacionReceta,
								values,
							]);
							props.setPreparacion([...props.preparacion, values]);

							handleClose();
						}}
					>
						{({ handleSubmit, handleChange }) => (
							<Form onSubmit={handleSubmit} id='formNuevoPasoPreparacion'>
								<FormLabel htmlFor='explicativo'>Explicativo</FormLabel>
								<FormControl
									as='textarea'
									name='explicativo'
									id='explicativo'
									onChange={handleChange}
								/>
								<br />
								<FormLabel htmlFor='tiempo'>Tiempo</FormLabel>
								<FormControl
									type='number'
									id='tiempo'
									name='tiempo'
									onChange={handleChange}
								/>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						variant='primary'
						form='formNuevoPasoPreparacion'
						type='submit'
					>
						Agregar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ModalAgregarPasoPreparacion;
