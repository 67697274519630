import { ADD_TOAST, REMOVE_TOAST } from "../actions/toastActions";

const initialState = {
	toast: [],
};

function toast(state = initialState, action) {
	switch (action.type) {
		case ADD_TOAST:
			return {
				...state,
				toast: action.payload.toast,
			};
		case REMOVE_TOAST:
			return (state = { toast: [] });

		default:
			return state;
	}
}

export default toast;
