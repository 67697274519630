import React, { useEffect } from "react";
import "./_Insumos.scss";
import { connect } from "react-redux";
import { Badge, Col, Container, Row, Button, Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";

import { updateCategoriasInsumos } from "@redux/actions/userActions.js";
import ListaInsumos from "./ListaInsumos";

function IndexInsumos(props) {
	const firebase = useFirebaseApp();

	const [
		categorias_insumo,
		loading_categorias_insumo,
		error_categorias_insumo,
	] = useCollection(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_insumo")
	);

	useEffect(() => {}, [loading_categorias_insumo]);

	let location = useLocation();

	return (
		<Container className="containerListaInsumos">
			<Row>
				<p className="titleLista">INSUMOS</p>
			</Row>
			<Row>
				<Col>
					<p className="subtitleLista">
						Materia prima, packaging, etc. que se usan para la produccion
					</p>
				</Col>
				<Col style={{ textAlign: "right" }}>
					<Button as={Link} to={`${location.pathname}/nuevo`}>
						Nuevo insumo
					</Button>
				</Col>
			</Row>
			<Table>
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Precio</th>
						<th>Cada</th>
						<th>Antiguedad</th>
						<th>Categoría</th>
						<th>Acciones</th>
					</tr>
				</thead>
				{!loading_categorias_insumo &&
					categorias_insumo.docs.map((categoria) => {
						return (
							<tbody key={categoria.id}>
								<ListaInsumos
									id_categoria={categoria.id}
									nombre_categoria={categoria.data().nombre}
								/>
							</tbody>
						);
					})}
			</Table>
		</Container>
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCategoriasInsumos: (categorias_insumo, establecimiento) =>
			dispatch(updateCategoriasInsumos(categorias_insumo, establecimiento)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexInsumos);
