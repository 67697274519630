import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Col, Container, Row } from 'react-bootstrap';
import { Formik, Field, ErrorMessage, FieldArray } from 'formik';
import { Form, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { useFirebaseApp } from 'reactfire';

import { useHistory } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';

function EditarProveedor(props) {
	const firebase = useFirebaseApp();
	const history = useHistory();

	const { id } = useParams();

	const [proveedor, loading_proveedor, error_proveedor] = useDocument(
		firebase
			.firestore()
			.collection('usuarios')
			.doc(props.usuario.id)
			.collection('establecimientos')
			.doc(props.usuario.establecimiento)
			.collection('proveedores')
			.doc(id)
	);

	return (
		!loading_proveedor && (
			<Container>
				<Formik
					initialValues={proveedor.data()}
					validate={(values) => {
						//TODO validar campos
						// const errors = {};
						// if (!values.nombre) {
						//   errors.nombre = "Requerido";
						// } else if (!values.apellido) {
						//   errors.apellido = "Requerido";
						// }
						// return errors;
					}}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(false);

						let data = {
							nombre: values.nombre,
							web: values.web,
							direccion: values.direccion,
							email: values.email,
							telefono: values.telefono,
						};

						// TODO crear función para armar los paths de forma más cómoda
						try {
							let updateRef = await proveedor.ref.update(data);

							// TODO: agregar mensaje de éxito cuando se hace submit
							console.log('actualizado');
						} catch (error) {
							// TODO: mostrar mensaje de error
							console.error(error.message);
						}

						setSubmitting(true);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<Form onSubmit={handleSubmit}>
							<br />
							<h3 className='titleLista'>Nuevo proveedor</h3>
							<hr />

							<Row>
								<Col>
									<Form.Label htmlFor='nombre'>Nombre</Form.Label>
									<Form.Control
										value={values.nombre}
										onChange={handleChange}
										onBlur={handleBlur}
										id='nombre'
										name='nombre'
										placeholder='Nombre'
									/>
								</Col>
								<Col>
									<Form.Label htmlFor='Web'>Web</Form.Label>
									<Form.Control
										value={values.web}
										onChange={handleChange}
										onBlur={handleBlur}
										id='web'
										name='web'
										placeholder='Página Web'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label htmlFor='Dirección'>Dirección</Form.Label>
									<Form.Control
										value={values.direccion}
										onChange={handleChange}
										onBlur={handleBlur}
										id='direccion'
										name='direccion'
										placeholder='Dirección'
									/>
								</Col>
								<Col>
									<Form.Label htmlFor='Email'>Email</Form.Label>
									<Form.Control
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										id='email'
										name='email'
										type='email'
										placeholder='Email'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label htmlFor='telefono'>Teléfono</Form.Label>
									<Form.Control
										value={values.telefono}
										onChange={handleChange}
										onBlur={handleBlur}
										id='telefono'
										name='telefono'
										placeholder='Nro de Teléfono'
										type='tel'
									/>
								</Col>
							</Row>
							<br />
							<Button type='submit' variant='primary' disabled={isSubmitting}>
								Actualizar
							</Button>

							<br />
						</Form>
					)}
				</Formik>
			</Container>
		)
	);
}

// export default EditarProveedor;

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(EditarProveedor);
