import React, { useEffect } from 'react';
import NavLeft from '../navegador/NavegadorLeft';
import NavegadorHeader from '../navegador/NavegadorHeader';
import { Route, Switch } from 'react-router';
import IndexProductos from '../productos/IndexProductos';
import NuevoProducto from '../productos/NuevoProducto';
import IndexInsumos from '../insumos/IndexInsumos';
import NuevoInsumo from '../insumos/NuevoInsumo';
import EditarInsumo from '../insumos/EditarInsumo';
import Planes from '../planes/Planes';
import IndexRecetas from '../recetas/IndexRecetas';
import NuevaReceta from '../recetas/NuevaReceta';
//import ListaProveedores from "../proveedores/ListaProveedores";
import IndexProveedores from '../proveedores/IndexProveedores';
import NuevoProveedor from '../proveedores/NuevoProveedor';
import EditarProveedor from '../proveedores/EditarProveedor';
import Configuracion from '../configuracion/Config';
import { Col, Row, Container, Spinner } from 'react-bootstrap';

import { useFirebaseApp } from 'reactfire';

import { useDocument } from 'react-firebase-hooks/firestore';
import DetallesProductos from '../productos/DetallesProductos';
import { useSelector } from 'react-redux';
import RecetaIndividual from '../recetas/RecetaIndividual';

function Wrapper() {
	return (
		<Switch>
			<Route exact path='/panel-de-control/productos'>
				<IndexProductos />
			</Route>
			<Route exact path='/panel-de-control/productos/nuevo'>
				<NuevoProducto />
			</Route>
			<Route exact path='/panel-de-control/productos/:categoria_producto/:id'>
				<DetallesProductos />
			</Route>
			<Route exact path='/panel-de-control/insumos'>
				<IndexInsumos />
			</Route>
			<Route exact path='/panel-de-control/insumos/nuevo'>
				<NuevoInsumo />
			</Route>
			<Route exact path='/panel-de-control/insumo/:categoria_insumo/:id'>
				<EditarInsumo />
			</Route>
			<Route exact path='/panel-de-control/planes'>
				<Planes />
			</Route>
			<Route exact path='/panel-de-control/recetas-base'>
				<IndexRecetas />
			</Route>
			<Route exact path='/panel-de-control/recetas-base/:idCategoria/:id'>
				<RecetaIndividual />
			</Route>
			<Route exact path='/panel-de-control/recetas-base/nueva'>
				<NuevaReceta />
			</Route>
			<Route exact path='/panel-de-control/proveedores'>
				<IndexProveedores />
			</Route>
			<Route exact path='/panel-de-control/proveedores/nuevo'>
				<NuevoProveedor />
			</Route>
			<Route exact path='/panel-de-control/proveedor/:id'>
				<EditarProveedor />
			</Route>
			<Route exact path='/panel-de-control/configuracion'>
				<Configuracion />
			</Route>
		</Switch>
	);
}

function Inicio() {
	const firebase = useFirebaseApp();

	const usuario = useSelector((state) => state.user);

	console.log(usuario);

	const [snapshot, loading, error] = useDocument(
		firebase
			.firestore()
			.collection(`usuarios/${usuario.id}/establecimientos`)
			.doc(`${usuario.establecimiento}`)
	);

	//TODO crear lógica para pedir categoría de recetas y recetas.
	useEffect(() => {}, [loading]);

	return (
		<div>
			<NavegadorHeader nombre={usuario.nombre} />
			<Container style={{ maxWidth: '100%' }}>
				{loading ? (
					<Spinner
						style={{ margin: 'auto' }}
						animation='border'
						variant='primary'
					/>
				) : (
					<Row>
						<Col xs={3}>
							<NavLeft establecimiento={snapshot.data().nombre} />
						</Col>
						<Col xs={8}>
							<Wrapper />
						</Col>
					</Row>
				)}
			</Container>
		</div>
	);
}

export default Inicio;
