import React, { useEffect } from "react";
import "./_Recetas.scss";
import { connect } from "react-redux";
import { Badge, Col, Container, Row, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";

import { updateCategoriasRecetas } from "@redux/actions/userActions.js";
import ListaRecetas from "./ListaRecetas";

function IndexRecetas(props) {
	const firebase = useFirebaseApp();

	const [
		categorias_receta,
		loading_categorias_receta,
		error_categorias_receta,
	] = useCollection(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_recetas_base")
	);

	useEffect(() => {}, [loading_categorias_receta]);

	let location = useLocation();

	return (
		<Container className='containerListaRecetas'>
			<Row>
				<p className='titleLista'>RECETAS BASE</p>
			</Row>
			<Row>
				<p className='subtitleLista'>
					Recetas que no se venden, se usan para los productos finales
				</p>
			</Row>
			<Row>
				<Col style={{ textAlign: "right" }}>
					<Button as={Link} to={`${location.pathname}/nueva`}>
						Nueva receta
					</Button>
				</Col>
			</Row>
			{!loading_categorias_receta &&
				categorias_receta.docs.map((categoria) => {
					return (
						<div key={categoria.id}>
							<Row>
								<Col>
									<h5>
										<Badge variant='primary' className='badgePrimary'>
											{categoria.data().nombre}
										</Badge>
									</h5>
								</Col>
							</Row>
							<ListaRecetas id_categoria={categoria.id} />
						</div>
					);
				})}
		</Container>
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateCategoriasRecetas: (categorias_receta, establecimiento) =>
			dispatch(updateCategoriasRecetas(categorias_receta, establecimiento)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexRecetas);
