import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoSVG from "../../assets/logo.svg";
import IconoUser from "../../assets/mujer.svg";
import IconoPass from "../../assets/corazon-fucsia.svg";

import { useFirebaseApp } from "reactfire";

import "./_login.scss";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";


function OlvideContrasena() {

  const firebase = useFirebaseApp();
  const [msg, setMsg] = useState(false);

  return (
    <div className="contenedorInicio container-xxl">
      <div className="card cardLogin">
        <img src={LogoSVG} className="logoFormLogin" />
        <div className="card-body">
          <p className="">OLVIDÉ MI CONTRASEÑA</p>
          <Formik
            initialValues={{
              email: "",
            }}
            validate={(values) => {
              //validar ingreso de contraseña
              const errors = {};
              if (!values.email) {
                errors.email = "Requerido";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Email inválido";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await firebase.auth()
                .sendPasswordResetEmail(values.email)
                .then(function() {
                  // TODO: cambiar por un mensaje apropiado
                  setMsg("Revise su casilla de correo para reestablecer la contraseña");
                }).catch(function(error) {
                  // TODO: cambiar por un error apropiado
                  setMsg(error.message);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <Row>
                    <p>{msg}</p>
                  </Row>
                  <Row className="justify-content-md-center pt-3">
                    <Col sm={1}>
                      <Form.Label>
                        <img className="iconoFormInput" src={IconoUser} />
                      </Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="inputLogin"
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <Form.Text>
                        {errors.email && touched.email && errors.email}
                      </Form.Text>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center pt-4 pl-2 pr-2">
                    <Button
                      block
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Enviar
                    </Button>
                  </Row>
                  <Row className="pt-3">
                    {errors.password && touched.password && errors.password}
                  </Row>
                  <Row className="pt-3 justify-content-center">
                    <Link to="/ingresar">Volver</Link>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default OlvideContrasena;
