import React, { useEffect } from "react";
import "./_Productos.scss";
import { useFirebaseApp } from "reactfire";
import { useCollection } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import Producto from "./Producto";

function ListaProductos(props) {
	const firebaseApp = useFirebaseApp();

	const [productos, loading_productos, error_productos] = useCollection(
		firebaseApp
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_producto")
			.doc(props.id_categoria)
			.collection("productos")
	);

	useEffect(() => {}, [loading_productos]);

	return (
		<Row>
			{!loading_productos &&
				productos.docs.map((producto) => {
					return (
						<Producto
							key={producto.id}
							id_categoria={props.id_categoria}
							id_producto={producto.id}
						/>
					);
				})}
		</Row>
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(ListaProductos);
