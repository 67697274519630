import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import LogoSVG from "../../assets/logo.svg";
import IconoCorazon from "../../assets/corazon-fucsia.svg";

import "./_ingreso-datos.scss";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";

import { useFirebaseApp } from "reactfire";

function IngresoDatos(props) {
  //Llamado a Firebase
  const firebase = useFirebaseApp();

  let user = firebase.auth().currentUser;

  let db = firebase.firestore();
  let [readyToGo, setReadyToGo] = useState(false);

  useEffect(() => {}, [readyToGo]);

  return (
    <div className="contenedorInicio container-xxl">
      {readyToGo && <Redirect exact to="/ingreso-establecimiento" />}
      <div className="card cardLogin">
        <img src={LogoSVG} className="logoFormLogin" />
        <div className="card-body">
          <Formik
            initialValues={{ nombre: "", apellido: "" }}
            validate={(values) => {
              //validar ingreso de contraseña
              const errors = {};
              if (!values.nombre) {
                errors.nombre = "Requerido";
              } else if (!values.apellido) {
                errors.apellido = "Requerido";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await user
                .updateProfile({
                  displayName: values.nombre + " " + values.apellido,
                })
                .then(() => {
                  console.log("Perfil actualizado");
                  console.log(user.displayName, user.uid);
                })
                .catch((error) => {
                  console.error("Error en la actualización de perfil: ", error);
                  setSubmitting(false);
                });

              await db
                .collection("usuarios")
                .doc(user.uid)
                .set({
                  nombre: values.nombre,
                  apellido: values.apellido,
                  establecimiento: null,
                })
                .then(() => {
                  console.log("Usuario actualizado correctamente");
                  setReadyToGo(true);
                  window.location.href = "/ingreso-establecimiento";
                })
                .catch((error) => {
                  console.error("Error al subir datos a Firestore: ", error);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <Row className="justify-content-md-center">
                    <p>
                      <b>BIENVENIDO A SWEET</b>
                    </p>
                  </Row>
                  <Row className="justify-content-md-center pt-3">
                    <Col sm={1}>
                      <Form.Label>
                        <img className="iconoFormInput" src={IconoCorazon} />
                      </Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="inputLogin"
                        placeholder="Nombre"
                        type="text"
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombre}
                      />
                      <Form.Text>
                        {errors.nombre && touched.nombre && errors.nombre}
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center pt-3">
                    <Col sm={1}>
                      <Form.Label>
                        <img className="iconoFormInput" src={IconoCorazon} />
                      </Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="inputLogin"
                        placeholder="Apellido"
                        type="text"
                        name="apellido"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apellido}
                      />
                      <Form.Text>
                        {errors.apellido && touched.apellido && errors.apellido}
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center pt-4 pl-2 pr-2">
                    <Button
                      block
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Siguiente
                    </Button>
                  </Row>
                  <Row className="pt-3"></Row>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default IngresoDatos;
