import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	InputGroup,
	Modal,
} from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useFirebaseApp } from 'reactfire';
import '../recetas/_Recetas.scss';

const ModalAgregarRecetaBase = (props) => {
	const firebaseApp = useFirebaseApp();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [options, setOptions] = useState([]);
	const [cant, setCant] = useState(null);

	const initRecetasBase = () => {
		let lista = [];

		firebaseApp
			.firestore()
			.collection(
				`usuarios/${props.id}/establecimientos/${props.establecimiento}/categorias_recetas_base/`
			)
			.get()
			.then((docs) => {
				//Request de categorias de recetas base completa.
				docs.forEach((categorias) => {
					lista.push(
						<option
							key={categorias.data().id}
							style={{ fontWeight: 'bold' }}
							value={categorias.id}
							disabled
						>
							{categorias.data().nombre}
						</option>
					);

					categorias.ref
						.collection(`recetas_base`)
						.get()
						.then((recetas_base) => {
							//Request de recetas base completa
							recetas_base.forEach((recetas) => {
								lista.push(
									<option
										key={recetas.data().id}
										value={JSON.stringify({
											categoria: {
												id: categorias.id,
												nombre: categorias.data().nombre,
											},
											receta_base: {
												nombre: recetas.data().nombre,
												id: recetas.id,
												medida: recetas.data().medida,
											},
										})}
									>
										{recetas.data().nombre}
									</option>
								);
							});
						});
				});
			});

		setOptions(lista);
	};

	useEffect(() => {
		initRecetasBase();
	}, []);

	return (
		<>
			<BsFillPlusCircleFill
				className='iconButtonAgregar'
				size={35}
				onClick={handleShow}
				title='Agregar receta base'
			/>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Agregar receta base</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							receta_base: '',
							cantidad: '',
							categorias_recetas_base: '',
						}}
						onSubmit={(values) => {
							props.setFieldValue('recetasUsadas', [
								...props.recetasUsadas,
								{
									categorias_recetas_base: values.categorias_recetas_base,
									receta_base: values.receta_base,
									cantidad: values.cantidad,
								},
							]);
							props.setRecetas_base([
								...props.recetas_base,
								{
									categorias_recetas_base: values.categorias_recetas_base,
									receta_base: values.receta_base,
									cantidad: values.cantidad,
								},
							]);
							handleClose();
						}}
					>
						{({ values, handleSubmit, handleChange, setFieldValue }) => (
							<Form onSubmit={handleSubmit} id='formAgregarRecetaBase'>
								<Form.Control
									name='receta_base'
									onChange={(e) => {
										setFieldValue(
											'receta_base',
											JSON.parse(e.target.value).receta_base
										);
										setFieldValue(
											'categorias_recetas_base',
											JSON.parse(e.target.value).categoria
										);
									}}
									as='select'
								>
									<option value=''>Seleccione una opcion</option>
									{options}
								</Form.Control>
								<br />
								<FormLabel htmlFor='cantidad'>Cantidad</FormLabel>
								<InputGroup>
									{!!values.receta_base && (
										<InputGroup.Text>
											{values.receta_base.medida}
										</InputGroup.Text>
									)}

									<FormControl
										id='cantidad'
										name='cantidad'
										onChange={handleChange}
										type='number'
										placeholder='Cantidad'
									/>
								</InputGroup>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button variant='primary' form='formAgregarRecetaBase' type='submit'>
						Agregar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ModalAgregarRecetaBase;
