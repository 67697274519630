import React from "react";
import {
  Image,
  Nav,
  Navbar,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import Logo from "../../assets/logo.svg";
import "./_Navegador.scss";
import CorazonIcono from "../../assets/corazon-fucsia.svg";
import { LogOut } from "utils/logout.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

function NavegadorHeader(props) {
  return (
    <Navbar expand="sm" className="containerNav">
      <Navbar.Brand>
        <img src={Logo} alt="Logo Sweet" className="logoNavegador" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav>
            <p className="navName">Hola, {props.nombre}!</p>
          </Nav>
        </Nav>
        <Nav className="navContainerButton">
          <Dropdown drop="left">
            <Dropdown.Toggle
              as={CustomToggle}
              className="toggleBtnConfig"
              id="dropdown-custom-components"
            >
              <img
                src={CorazonIcono}
                className="imgButton"
                alt="Toggle button"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/panel-de-control/configuracion">
                Configuración
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/ingreso-establecimiento">
                Nuevo establecimiento
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={LogOut}>
                Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavegadorHeader;
