import React from 'react';
import { ButtonGroup, Image, Badge } from 'react-bootstrap';
import './_Navegador.scss';
import { Link, useLocation } from 'react-router-dom';

// Iconos importados
import IconoClip from '@assets/iconos/ico-clip.svg';
import IconoConfig from '@assets/iconos/ico-config.svg';
import IconoEstrella from '@assets/iconos/ico-estrella.svg';
import IconoRegalo from '@assets/iconos/ico-regalo.svg';
import IconoVarita from '@assets/iconos/ico-varita.svg';

import IconoClipSelect from '@assets/iconos/ico-clip-rosa.svg';
import IconoConfigSelect from '@assets/iconos/ico-config-rosa.svg';
import IconoEstrellaSelect from '@assets/iconos/ico-estrella-rosa.svg';
import IconoRegaloSelect from '@assets/iconos/ico-regalo-rosa.svg';
import IconoVaritaSelect from '@assets/iconos/ico-varita-rosa.svg';

// TODO: Crear logica  con parametros de ruta para cambiar el estilo de los botones
// TODO: Modificar estilos de botones

export default function NavegadorLeft({ establecimiento }) {
	let location = useLocation().pathname;

	return (
		<div className='containerNavLeft d-flex flex-column justify-content-center'>
			<Badge variant='primary' className='badgeEstablecimiento'>
				{establecimiento}
			</Badge>
			{/* Grupo de botones del navegador */}
			<ButtonGroup vertical size='sm'>
				<Link
					to='/panel-de-control/productos'
					className={
						location.includes('/productos') ? 'BtnNavLeftSelect' : 'BtnNavLeft'
					}
				>
					{location.includes('/productos') ? (
						<Image src={IconoEstrellaSelect} className='IconoBtnNavLeft' />
					) : (
						<Image src={IconoEstrella} className='IconoBtnNavLeft' />
					)}
					Productos
				</Link>
				<Link
					to='/panel-de-control/recetas-base'
					className={
						location.includes('/recetas-base')
							? 'BtnNavLeftSelect'
							: 'BtnNavLeft'
					}
				>
					{location.includes('/recetas-base') ? (
						<Image src={IconoVaritaSelect} className='IconoBtnNavLeft' />
					) : (
						<Image src={IconoVarita} className='IconoBtnNavLeft' />
					)}
					Recetas Base
				</Link>
				<Link
					to='/panel-de-control/insumos'
					className={
						location.includes('insumos') ? 'BtnNavLeftSelect' : 'BtnNavLeft'
					}
				>
					{location.includes('insumos') ? (
						<Image src={IconoRegaloSelect} className='IconoBtnNavLeft' />
					) : (
						<Image src={IconoRegalo} className='IconoBtnNavLeft' />
					)}
					Insumos
				</Link>
				<Link
					to='/panel-de-control/planes'
					className={
						location.includes('planes') ? 'BtnNavLeftSelect' : 'BtnNavLeft'
					}
				>
					{location.includes('planes') ? (
						<Image src={IconoClipSelect} className='IconoBtnNavLeft' />
					) : (
						<Image src={IconoClip} className='IconoBtnNavLeft' />
					)}
					Planes
				</Link>
				<Link
					to='/panel-de-control/proveedores'
					className={
						location.includes('proveedores') ? 'BtnNavLeftSelect' : 'BtnNavLeft'
					}
				>
					{location.includes('proveedores') ? (
						<Image src={IconoConfigSelect} className='IconoBtnNavLeft' />
					) : (
						<Image src={IconoConfig} className='IconoBtnNavLeft' />
					)}
					Proveedores
				</Link>
				<Link
					to='/panel-de-control/configuracion'
					className={
						location.includes('configuracion')
							? 'BtnNavLeftSelect'
							: 'BtnNavLeft'
					}
				>
					{location.includes('configuracion') ? (
						<Image src={IconoConfigSelect} className='IconoBtnNavLeft' />
					) : (
						<Image src={IconoConfig} className='IconoBtnNavLeft' />
					)}
					Configuración
				</Link>
			</ButtonGroup>
		</div>
	);
}
