import {
	UPDATE_ESTABLECIMIENTO,
	UPDATE_ID,
	UPDATE_NOMBRE,
	UPDATE_CATEGORIAS_PRODUCTOS,
	UPDATE_CATEGORIAS_RECETAS,
	UPDATE_CATEGORIAS_INSUMOS,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
} from '../actions/userActions';

import { initialState } from './initialState';

// const initialState = {
//   nombre: "",
//   apellido: "",
//   establecimiento: "",
//   id: 0,
// };

function user(state = initialState, action) {
	switch (action.type) {
		case UPDATE_NOMBRE:
			return {
				...state,
				nombre: action.payload.nombre,
				apellido: action.payload.apellido,
			};
		case UPDATE_ESTABLECIMIENTO:
			return {
				...state,
				establecimiento: action.payload.establecimiento,
			};
		case UPDATE_ID:
			return {
				...state,
				id: action.payload.id,
			};
		case UPDATE_CATEGORIAS_PRODUCTOS:
			return {
				...state,
				[action.payload.establecimiento]: {
					categorias_productos: action.payload.categorias_productos,
				},
			};
		case UPDATE_CATEGORIAS_RECETAS:
			return {
				...state,
				[action.payload.establecimiento]: {
					categorias_receta: action.payload.categorias_receta,
				},
			};
		case UPDATE_CATEGORIAS_INSUMOS:
			return {
				...state,
				[action.payload.establecimiento]: {
					categorias_insumo: action.payload.categorias_insumo,
				},
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload.user,
			};
		case LOGOUT_SUCCESS:
			return {
				user: null,
			};
		default:
			return state;
	}
}

export default user;
