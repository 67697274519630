import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoSVG from "../../assets/logo.svg";
import IconoUser from "../../assets/mujer.svg";
import IconoPass from "../../assets/corazon-fucsia.svg";

import "./_login.scss";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Col, Form, Row } from "react-bootstrap";
import AJAX from "../../utils/axios";

import { useFirebaseApp } from "reactfire";

function Login() {
  //Llamado a Firebase
  const firebase = useFirebaseApp();

  // TODO: modificar para que el error se muestre en castellano
  const [auth_error, setAuthError] = useState(false);

  return (
    <div className="contenedorInicio container-xxl">
      <div className="card cardLogin">
        <img src={LogoSVG} className="logoFormLogin" />
        <div className="card-body">
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              //validar ingreso de contraseña
              const errors = {};
              if (!values.email) {
                errors.email = "Requerido";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Email inválido";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                const RESPONSE = await AJAX.post("/endpoint/usuarios/auth", {
                  email: values.email,
                  password: values.password,
                });

                if (RESPONSE.status === 200) {
                  if (RESPONSE.data.success) {
                  } else {
                    setAuthError({ message: RESPONSE.data.error.message });
                  }
                } else {
                  throw new Error(JSON.stringify(RESPONSE));
                }
              } catch (error) {
                console.error(error);

                setAuthError({
                  message: "Error interno",
                });
              }

              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                {auth_error && (
                  <Alert
                    variant="danger"
                    onClose={() => setAuthError(false)}
                    dismissible
                  >
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{auth_error.message}</p>
                  </Alert>
                )}
                <div className="form-group">
                  <Row className="justify-content-md-center pt-3">
                    <Col sm={1}>
                      <Form.Label>
                        <img className="iconoFormInput" src={IconoUser} />
                      </Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="inputLogin"
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <Form.Text>
                        {errors.email && touched.email && errors.email}
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center pt-3">
                    <Col sm={1}>
                      <Form.Label>
                        <img className="iconoFormInput" src={IconoPass} />
                      </Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="inputLogin"
                        placeholder="Contraseña"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center pt-4 pl-2 pr-2">
                    <Button
                      block
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Ingresar
                    </Button>
                  </Row>
                  <Row className="pt-3">
                    {errors.password && touched.password && errors.password}
                  </Row>
                  <Row className="pt-3 justify-content-center">
                    <Link to="/olvide-contrasena">
                      ¡Ups! Olvidé mi contraseña
                    </Link>
                  </Row>
                  <Row className="pt-3 pb-3 pl-2 pr-2 justify content center">
                    <Button
                      as={Link}
                      to="/nueva-cuenta"
                      variant="outline-primary"
                      block
                    >
                      Me quiero registrar
                    </Button>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
