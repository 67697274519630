import React from "react";
import { Link } from "react-router-dom";
import LogoSVG from "../../assets/logo.svg";

import "./_login.scss";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";

function CuentaCreada() {
  return (
    <div className="contenedorInicio container-xxl">
      <div className="card cardLogin ">
        <img src={LogoSVG} className="logoFormLogin" />
        <div className="card-body ">
          <Row className="justify-content-md-center">
            <p className="">
              <b>Su cuenta ha sido creada correctamente.</b>
            </p>
          </Row>
          <Row className="justify-content-md-center pt-4 pl-2 pr-2">
            <Button block as={Link} to="/ingresar">
              Volver al inicio
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default CuentaCreada;
