import './style/App.scss';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import { Provider, useSelector } from 'react-redux';
import store from './redux/store';

import Login from './layout/login/Login';
import CrearCuenta from './layout/login/CrearCuenta';
import OlvideContrasena from './layout/login/OlvideContrasena';

import Authed from './layout/authed/Authed';

function FirstRouting() {
	const { user } = useSelector((state) => state.user);

	console.log(user);

	if (user) {
		// User se autenticó y hay sesión y se le pasa el estado global (store) con el provider de Redux
		return <Authed user={user} />;
	} else {
		// User no se autenticó o no existe
		return (
			<Router>
				<Switch>
					<Redirect exact from='/' to='/ingresar' />
					<Redirect exact from='/primer-ingreso' to='/ingresar' />
					<Redirect exact from='/panel-de-control' to='/ingresar' />
					<Redirect exact from='/panel-de-control/*' to='/ingresar' />
					<Route exact path='/ingresar'>
						<Login />
					</Route>
					<Route exact path='/nueva-cuenta'>
						<CrearCuenta />
					</Route>
					<Route exact path='/olvide-contrasena'>
						<OlvideContrasena />
					</Route>
				</Switch>
			</Router>
		);
	}
}

function App() {
	return (
		<Provider store={store}>
			<FirstRouting />
		</Provider>
	);
}

export default App;
