import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import { Form, Button } from "react-bootstrap";

import { connect } from "react-redux";
import { useFirebaseApp } from "reactfire";

import { useHistory } from "react-router-dom";

import {
	useDocument,
	useCollection,
	useCollectionData,
} from "react-firebase-hooks/firestore";

const initialValues = {
	nombre: "",
	categoria: "",
	medida: "",
	precio: "",
	descripcion: "",
};

function NuevoProveedor(props) {
  const firebase = useFirebaseApp();
  const history = useHistory();

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          //TODO validar campos
          // const errors = {};
          // if (!values.nombre) {
          //   errors.nombre = "Requerido";
          // } else if (!values.apellido) {
          //   errors.apellido = "Requerido";
          // }
          // return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // await new Promise((r) => setTimeout(r, 500));
          // alert(JSON.stringify(values, null, 2));
          
          setSubmitting(false);

          let data = {
            nombre: values.nombre || initialValues.nombre,
            web: values.web || initialValues.web,
            direccion: values.direccion || initialValues.direccion,
            email: values.email || initialValues.email,
            telefono: values.telefono || initialValues.telefono,
          }
          
          // TODO crear función para armar los paths de forma más cómoda
          try {
            let updateRef = await firebase.firestore()
              .collection("usuarios")
              .doc(props.usuario.id)
              .collection("establecimientos")
              .doc(props.usuario.establecimiento)
              .collection("proveedores")
              .add(data)

            // TODO: agregar mensaje de éxito cuando se hace submit
            console.log("Nuevo proveedor agregado")
            history.goBack();
          } catch(error) {
            // TODO: mostrar mensaje de error
            console.error(error.message)
          }

          setSubmitting(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <br />
            <h3 className="titleLista">Nuevo proveedor</h3>
            <hr />

            <Row>
              <Col>
                <Form.Label htmlFor="nombre">Nombre</Form.Label>
                <Form.Control
                  value={values.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                />
              </Col>
              <Col>
                <Form.Label htmlFor="Web">Web</Form.Label>
                <Form.Control
                  value={values.web}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="web"
                  name="web"
                  placeholder="Página Web"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label htmlFor="Dirección">Dirección</Form.Label>
                <Form.Control
                  ºvalue={values.direccion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="direccion"
                  name="direccion"
                  placeholder="Dirección"
                />
              </Col>
              <Col>
                <Form.Label htmlFor="Email">Email</Form.Label>
                <Form.Control
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label htmlFor="telefono">Teléfono</Form.Label>
                <Form.Control
                  value={values.telefono}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="telefono"
                  name="telefono"
                  placeholder="Nro de Teléfono"
                  type="tel"
                />
              </Col>
            </Row>
            <br />
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              Crear
            </Button>

            <br />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

// export default NuevoProveedor;

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(NuevoProveedor);
