import React, { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
	Table,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import './_Productos.scss';
import { Formik } from 'formik';
import { BsArrowBarDown, BsArrowBarUp, BsTrash } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { useFirebaseApp } from 'reactfire';

import { useCollection } from 'react-firebase-hooks/firestore';
import ModalAgregarInsumo from '../recetas/ModalAgregarInsumo';
import ModalAgregarPasoPreparacion from '../recetas/ModalAgregarPasoPreparacion';
import ModalAgregarRecetaBase from './ModalAgregarRecetaBase';

const initialValues = {
	nombre: '',
	categoria: '',
	cantidad: 0,
	medida: '',
	descripcion: '',
	recetasUsadas: [],
	insumosUsados: [],
	preparacionReceta: [],
};

function NuevoProducto(props) {
	const firebaseApp = useFirebaseApp();
	const history = useHistory();

	//ruta para storage de imgen

	const [
		categorias_productos,
		loading_categorias_productos,
		error_categorias_productos,
	] = useCollection(
		firebaseApp
			.firestore()
			.collection(
				`usuarios/${props.usuario.id}/establecimientos/${props.usuario.establecimiento}/categorias_producto/`
			)
	);

	const [insumos, setInsumos] = useState([]);
	const [recetas_base, setRecetas_base] = useState([]);
	const [preparacion, setPreparacion] = useState([]);
	const [changePasos, setChangePasos] = useState(false);

	const tiempoTotalPreparacion = (pasos) => {
		let acumulador = 0;

		pasos.forEach((paso) => (acumulador += paso.tiempo));
		return acumulador;
	};

	const reordenaPasos = (index, orden) => {
		let array = preparacion;
		if (orden === 'up') {
			const flag = preparacion[index];
			array[index] = array[index - 1];
			array[index - 1] = flag;
		} else {
			const flag = preparacion[index];
			array[index] = array[index + 1];
			array[index + 1] = flag;
		}
		setChangePasos(!changePasos);
		setPreparacion(array);
	};

	const borrarPaso = (index) => {
		preparacion.splice(index, 1);
		setChangePasos(!changePasos);
	};

	useEffect(() => {}, [changePasos]);

	return (
		<Container>
			<Formik
				initialValues={initialValues}
				validate={(values) => {
					//TODO validar campos
				}}
				onSubmit={async (values, { setSubmitting }) => {
					if (!values.categoria) {
						return alert('Seleccione una categoría');
					}

					if (!values.nombre) {
						return alert('Ingrese un nombre');
					}

					if (!values.medida) {
						return alert('Seleccione una medida');
					}

					if (!values.cantidad) {
						return alert('Ingrese una cantidad');
					}

					if (!values.descripcion) {
						return alert('Ingrese una descripción');
					}

					if (!values.recetasUsadas) {
						return alert('Seleccione una receta base');
					}

					if (!values.insumosUsados) {
						return alert('Seleccione un insumo');
					}

					if (!values.preparacionReceta) {
						return alert('Ingrese una preparación');
					}

					console.log('values', values);

					setSubmitting(false);

					// TODO crear función para armar los paths de forma más cómoda

					try {
						let fileName = '';

						if (values.imagen !== '') {
							const refImagen = firebase
								.storage()
								.ref(`${props.usuario.id}/imagenes/productos/`);

							let uploadTask = refImagen
								.child(`${values.nombre}`)
								.put(values.imagen);

							await uploadTask.on(
								firebase.storage.TaskEvent.STATE_CHANGED,
								function (snapshot) {
									// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
									var progress =
										(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
									console.log('Upload is ' + progress + '% done');
									// eslint-disable-next-line default-case
									switch (snapshot.state) {
										case firebase.storage.TaskState.PAUSED: // or 'paused'
											console.log('Upload is paused');
											break;
										case firebase.storage.TaskState.RUNNING: // or 'running'
											console.log('Upload is running');
											break;
									}
								},
								function (error) {
									console.log('error en subir imagen', error);
									// A full list of error codes is available at
									// https://firebase.google.com/docs/storage/web/handle-errors
									switch (error.code) {
										case 'storage/unauthorized':
											// User doesn't have permission to access the object
											break;

										case 'storage/canceled':
											// User canceled the upload
											break;
										case 'storage/unknown':
											// Unknown error occurred, inspect error.serverResponse
											break;
										default:
											break;
									}
								}
							);

							fileName = await uploadTask.snapshot.ref
								.getDownloadURL()
								.then(function (downloadURL) {
									return downloadURL;
								});
						}

						let updateRef = await firebaseApp
							.firestore()
							.collection('usuarios')
							.doc(props.usuario.id)
							.collection('establecimientos')
							.doc(props.usuario.establecimiento)
							.collection('categorias_producto');

						let nueva;

						if (values.categoria == 'new') {
							nueva = await updateRef.add({
								nombre: values.nueva_categoria,
							});
						}

						let data = {
							nombre: values.nombre,
							cantidad: values.cantidad,
							medida: values.medida,
							descripcion: values.descripcion,
							imagen: fileName,
							insumos: values.insumosUsados,
							preparacion: values.preparacionReceta,
							recetas_base: values.recetasUsadas,
						};

						await updateRef
							.doc(nueva ? nueva.id : values.categoria)
							.collection('productos')
							.add(data);

						// TODO: agregar mensaje de éxito cuando se hace submit
						console.log('Nuevo producto agregado');
						history.goBack();
					} catch (error) {
						// TODO: mostrar mensaje de error
						console.error(error.message);
					}

					setSubmitting(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					isSubmitting,
				}) => (
					<Form onSubmit={handleSubmit}>
						<br />
						<h3 className='titleLista'>Nuevo producto</h3>
						<hr />
						<Container>
							<Row
								style={{
									height: '200px',
								}}
							>
								<Col xs={3}>
									<Card
										style={{
											height: '100%',
											width: '100%',
											backgroundColor: 'lightGray',
										}}
									>
										<Button
											className='cardFileSelector'
											as='input'
											style={{
												height: '100%',
												width: '100%',
												opacity: '0',
											}}
											id='imagen'
											name='imagen'
											type='file'
											onChange={(event) => {
												setFieldValue('imagen', event.currentTarget.files[0]);
											}}
											onBlur={handleBlur}
											accept='image/png, image/jpeg'
										/>
									</Card>
								</Col>
								<Col xs={9} style={{ alignSelf: 'flex-end' }}>
									<Form.Label htmlFor='descripcion'>
										Descripción del producto
									</Form.Label>
									<Form.Control
										id='descripcion'
										name='descripcion'
										placeholder='Descripción'
										as='textarea'
										value={values.descripcion}
										onChange={handleChange}
										onBlur={handleBlur}
										rows={5}
									/>
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Label htmlFor='nombre'>Nombre del producto</Form.Label>
									<Form.Control
										value={values.nombre}
										onChange={handleChange}
										onBlur={handleBlur}
										id='nombre'
										name='nombre'
										placeholder='Receta'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label htmlFor='categoria'>Categoría</Form.Label>
									<Form.Control
										id='categoria'
										name='categoria'
										placeholder='Categoria'
										as='select'
										value={values.categoria}
										onChange={handleChange}
										onBlur={handleBlur}
									>
										<option>Seleccione categoría</option>
										{!loading_categorias_productos &&
											categorias_productos.docs.map((cat) => (
												<option key={cat.id} value={cat.id}>
													{cat.data().nombre}
												</option>
											))}
										<option disabled>-----------------</option>
										<option value='new' key='none'>
											Agregar nueva categoría
										</option>
									</Form.Control>

									{values.categoria === 'new' && (
										<Form.Control
											id='nueva_categoria'
											name='nueva_categoria'
											placeholder='Nombre de la nueva categoría'
											value={values.nueva_categoria}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									)}
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Label htmlFor='cantidad'>
										Cantidad{' '}
										<span style={{ fontSize: 'small', fontWeight: 'bold' }}>
											que sale con esta receta
										</span>
									</Form.Label>
									<Form.Control
										id='cantidad'
										name='cantidad'
										placeholder='Cantidad resultante'
										type='number'
										value={values.cantidad}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</Col>
								<Col>
									<Form.Label htmlFor='medida'>Medida</Form.Label>
									<Form.Control
										id='medida'
										name='medida'
										placeholder='Medida'
										as='select'
										value={values.medida}
										onChange={handleChange}
										onBlur={handleBlur}
									>
										<option>Unidad de medida</option>
										<option value='mm3'>Milimetros Cúbicos - mm3</option>
										<option value='cm3'>Centimetros Cúbicos - cm3</option>
										<option value='m3'>Metros Cúbicos - m3</option>
										<option value='mm'>Milimentros - mm</option>
										<option value='cm'>Centímetros - cm</option>
										<option value='m'>Metros - m</option>
										<option value='mg'>Miligramos - mg</option>
										<option value='gr'>Gramos - gr</option>
										<option value='kg'>Kilos - kg</option>
										<option value='u'>Unidades - u</option>
									</Form.Control>
								</Col>
							</Row>
							<br />
							<hr />
							<Row>
								<Col xs={10}>
									<h3 className='titleLista'>Recetas Base</h3>
									<p className='subtitleLista'>Para hacer este producto </p>
								</Col>
								<Col
									xs={2}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<ModalAgregarRecetaBase
										id={props.usuario.id}
										establecimiento={props.usuario.establecimiento}
										setRecetas_base={setRecetas_base}
										recetas_base={recetas_base}
										setFieldValue={setFieldValue}
										recetasUsadas={values.recetasUsadas}
									/>
								</Col>
							</Row>
							{recetas_base.length > 0 && (
								<Row>
									<Table>
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Cantidad</th>
											</tr>
										</thead>
										<tbody>
											{recetas_base.map((item) => {
												return (
													<tr key={item.receta_base.id}>
														<td>{item.receta_base.nombre}</td>
														<td>
															{item.cantidad} {item.receta_base.medida}
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</Row>
							)}
							<br />
							<hr />
							<Row>
								<Col xs={10}>
									<h3 className='titleLista'>Insumos</h3>
									<p className='subtitleLista'>Para hacer este producto </p>
								</Col>
								<Col
									xs={2}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<ModalAgregarInsumo
										id={props.usuario.id}
										establecimiento={props.usuario.establecimiento}
										setInsumos={setInsumos}
										insumos={insumos}
										setFieldValue={setFieldValue}
										insumosUsados={values.insumosUsados}
									/>
								</Col>
							</Row>
							{insumos.length > 0 && (
								<Row>
									<Table>
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Cantidad</th>
												<th>Precio</th>
											</tr>
										</thead>
										<tbody>
											{insumos.map((item) => {
												return (
													<tr key={item.insumo.id}>
														<td>{item.insumo.nombre}</td>
														<td>{item.cantidad}</td>
														<td>{item.insumo.precio}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</Row>
							)}
							<br />
							<hr />
							<Row>
								<Col xs={10}>
									<h3 className='titleLista'>Preparacion</h3>
									<p className='subtitleLista'>Para hacer este producto </p>
								</Col>
								<Col
									xs={2}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<ModalAgregarPasoPreparacion
										id={props.usuario.id}
										establecimiento={props.usuario.establecimiento}
										setPreparacion={setPreparacion}
										preparacion={preparacion}
										setFieldValue={setFieldValue}
										preparacionReceta={values.preparacionReceta}
									/>
								</Col>
							</Row>

							{preparacion.length > 0 && (
								<Container>
									<Row style={{ width: '100%' }}>
										<Col xs={2} style={{ padding: '10px', width: '100%' }}>
											<Badge className='badgeLightGreen'>
												Tiempo total: {tiempoTotalPreparacion(preparacion)} Min.
											</Badge>
										</Col>
										<Col xs={10}></Col>
									</Row>
									{preparacion.map((paso, index) => {
										return (
											<Row
												key={index}
												style={{ padding: '10px', width: '100%' }}
											>
												<Col xs={2}>
													<Row style={{ padding: '5px', width: '100%' }}>
														<Badge
															className='badgeLightGray'
															style={{ width: '100%' }}
														>
															Paso Nro. {index + 1}
														</Badge>
													</Row>
													<Row style={{ padding: '5px', width: '100%' }}>
														<Badge
															className='badgeGray'
															style={{ width: '100%' }}
														>
															{paso.tiempo} Min.
														</Badge>
													</Row>
													<Row
														style={{
															width: '100%',
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'space-around',
														}}
													>
														{index > 0 && (
															<BsArrowBarUp
																onClick={() => reordenaPasos(index, 'up')}
															/>
														)}

														{index < preparacion.length - 1 && (
															<BsArrowBarDown
																onClick={() => reordenaPasos(index, 'down')}
															/>
														)}
														{preparacion.length > 1 && (
															<BsTrash onClick={() => borrarPaso(index)} />
														)}
													</Row>
												</Col>
												<Col xs={10}>
													<div
														style={{
															borderRadius: '5px',
															padding: '10px',
															color: 'gray',
															width: '100%',
															height: '100%',
															backgroundColor: 'lightgray',
															fontWeight: 'normal',
															textAlign: 'left',
															fontSize: 'medium',
														}}
													>
														{paso.explicativo}
													</div>
												</Col>
											</Row>
										);
									})}
								</Container>
							)}

							<Button type='submit' variant='primary' disabled={isSubmitting}>
								Crear producto
							</Button>
						</Container>
					</Form>
				)}
			</Formik>
		</Container>
	);
}

// export default NuevoProducto;

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(NuevoProducto);
