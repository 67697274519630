import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useFirebaseApp } from 'reactfire';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useSelector } from 'react-redux';
import { Form, Container, Row, Col, Card, Badge, Table } from 'react-bootstrap';
import './_Productos.scss';

const DetallesProductos = (props) => {
	const usuario = useSelector((state) => state.user);
	const firebase = useFirebaseApp();

	const [productPic, setPic] = useState();

	let { categoria_producto, id } = useParams();

	const [producto, loading_producto, error_producto] = useDocument(
		firebase
			.firestore()
			.collection('usuarios')
			.doc(usuario.id)
			.collection('establecimientos')
			.doc(usuario.establecimiento)
			.collection('categorias_producto')
			.doc(categoria_producto)
			.collection('productos')
			.doc(id)
	);

	const [categoria, loading_categoria, error_categoria] = useDocument(
		firebase
			.firestore()
			.collection('usuarios')
			.doc(usuario.id)
			.collection('establecimientos')
			.doc(usuario.establecimiento)
			.collection('categorias_producto')
			.doc(categoria_producto)
	);

	const calculaTiempoTotal = (preparacion) => {
		let acumulador = 0;

		preparacion.forEach((paso) => (acumulador += paso.tiempo));

		return acumulador;
	};

	function getPic(doc, firebase, setPic) {
		if (!doc) {
			return;
		}

		const data = doc.data();

		if (!data.imagen) {
			return setPic(
				'https://via.placeholder.com/158x237/?text=The+cake+is+a+lie'
			);
		}

		const ref = firebase.storage().refFromURL(data.imagen);

		ref
			.getDownloadURL()
			.then((url) => {
				setPic(url);
			})
			.catch((e) => {
				console.error('[getPic Error]', e);
				setPic('https://via.placeholder.com/158x237/?text=The+cake+is+a+lie');
			});
	}

	useEffect(() => {
		getPic(producto, firebase, setPic);
	}, [producto]);

	return (
		!loading_categoria &&
		!loading_producto && (
			<Container className='containerListaProductos'>
				<Row>
					<Col xs={3}>
						<Card
							style={{
								height: '100%',
								width: '100%',
							}}
						>
							<Card.Img src={productPic} alt='imagen de producto' />
						</Card>
					</Col>
					<Col xs={9}>
						<Row style={{ width: '100%' }}>
							<Card
								style={{
									backgroundColor: 'lightGray',
									border: 'none',
									width: '100%',
								}}
							>
								<Card.Body className='cardTitle'>
									<Card.Text>{producto.data().nombre.toUpperCase()}</Card.Text>
								</Card.Body>
							</Card>
						</Row>
						<br />
						<Row>
							<Badge
								style={{
									backgroundColor: 'lightGray',
									color: '#5d5d5d',
									padding: '5px',
								}}
							>
								{categoria.data().nombre.toUpperCase()}
							</Badge>
						</Row>
						<br />
						<Row style={{ width: '100%' }}>
							<Card className='cardDescripcion'>
								{producto.data().descripcion}
							</Card>
						</Row>
						<br />
						<Row style={{ width: '100%' }}>
							<Container>
								<Row>
									<Col>
										<Row>
											<span className='title'>Rendimiento:</span>
										</Row>
										<Row>
											<Badge
												style={{
													backgroundColor: 'lightGray',
													color: '#5d5d5d',
													padding: '5px',
												}}
											>
												{producto.data().cantidad +
													' ' +
													producto.data().medida}
											</Badge>
										</Row>
									</Col>
									<Col>
										<Row>
											<span>Tiempo:</span>
										</Row>
										<Row>
											<Badge
												style={{
													backgroundColor: 'lightGray',
													color: '#5d5d5d',
													padding: '5px',
												}}
											>
												{calculaTiempoTotal(producto.data().preparacion)}{' '}
												minutos
											</Badge>
										</Row>
									</Col>
									<Col>
										<Row>
											<span>Costo Total:</span>
										</Row>
										<Row>
											<Badge
												style={{
													backgroundColor: 'lightGray',
													color: '#5d5d5d',
													padding: '5px',
												}}
											>
												xxx
											</Badge>
										</Row>
									</Col>
								</Row>
							</Container>
						</Row>
					</Col>
				</Row>

				<hr />
				<Row>
					<p className='titleLista'>RECETAS BASE</p>
				</Row>
				<Row>
					<p className='subtitleLista'>Para hacer el producto terminado</p>
				</Row>

				<Row>
					<Table responsive borderless size='sm'>
						<thead>
							<tr>
								<th className='headerLista'>#</th>
								<th className='headerLista' style={{ width: 250 }}>
									Nombre
								</th>
								<th className='headerLista'>Cantidad</th>
								<th className='headerLista'>Costo</th>
								<th className='headerLista'>Antiguedad</th>
							</tr>
						</thead>
						<tbody>
							{producto.data().recetas_base.map((receta, index) => {
								let flag_categoria = '';

								if (receta.categorias_recetas_base.nombre === flag_categoria) {
									return (
										<tr key={index}>
											<td className='celdaLista'>{index}</td>
											<td className='celdaLista'>
												{receta.receta_base.nombre}
											</td>
											<td className='celdaLista'>{receta.cantidad}</td>
											<td className='celdaLista'>Table cell</td>
											<td className='celdaLista'>Table cell</td>
										</tr>
									);
								} else {
									flag_categoria = receta.categorias_recetas_base.nombre;
									return (
										<>
											<tr key={index}>
												<td className='titleCategoriaLista' colSpan={5}>
													{receta.categorias_recetas_base.nombre}
												</td>
											</tr>
											<tr>
												<td className='celdaLista'>{index}</td>
												<td className='celdaLista'>
													{receta.receta_base.nombre}
												</td>
												<td className='celdaLista'>{receta.cantidad}</td>
												<td className='celdaLista'>xxx</td>
												<td className='celdaLista'>xxx</td>
											</tr>
										</>
									);
								}
							})}
						</tbody>
					</Table>
				</Row>
				<hr />
				<Row>
					<p className='titleLista'>INSUMOS</p>
				</Row>
				<Row>
					<p className='subtitleLista'>Para hacer el producto terminado</p>
				</Row>

				<Row>
					<Table responsive borderless size='sm'>
						<thead>
							<tr>
								<th className='headerLista'>#</th>
								<th className='headerLista' style={{ width: 250 }}>
									Nombre
								</th>
								<th className='headerLista'>Cantidad</th>
								<th className='headerLista'>Costo</th>
								<th className='headerLista'>Antiguedad</th>
							</tr>
						</thead>
						<tbody>
							{producto.data().insumos.map((insumo, index) => {
								let flag_categoria = '';

								if (insumo.categoria_insumo.nombre === flag_categoria) {
									return (
										<tr key={index}>
											<td className='celdaLista'>{index}</td>
											<td className='celdaLista'>{insumo.nombre}</td>
											<td className='celdaLista'>Table cell</td>
											<td className='celdaLista'>Table cell</td>
											<td className='celdaLista'>Table cell</td>
										</tr>
									);
								} else {
									flag_categoria = insumo.categoria_insumo.nombre;
									return (
										<>
											<tr key={index}>
												<td className='titleCategoriaLista' colSpan={5}>
													{insumo.categoria_insumo.nombre}
												</td>
											</tr>
											<tr>
												<td className='celdaLista'>{index}</td>
												<td className='celdaLista'>{insumo.insumo.nombre}</td>
												<td className='celdaLista'>{insumo.cantidad}</td>
												<td className='celdaLista'>xxx</td>
												<td className='celdaLista'>xxx</td>
											</tr>
										</>
									);
								}
							})}
						</tbody>
					</Table>
				</Row>
				<hr />
				<Row>
					<p className='titleLista'>PREPARACION</p>
				</Row>
				<Row>
					<p className='subtitleLista'>Para hacer el producto terminado</p>
				</Row>

				<Row>
					<p>
						Tiempo total{' '}
						<b>{calculaTiempoTotal(producto.data().preparacion)} min. </b>
					</p>
				</Row>

				{producto.data().preparacion.map((paso, index) => (
					<Row key={index}>
						<Container>
							<Row>
								<Badge
									style={{
										backgroundColor: 'lightGray',
										color: '#5d5d5d',
										padding: '5px',
									}}
								>
									Tiempo
								</Badge>
							</Row>
						</Container>
						<Row style={{ padding: '10px', width: '100%' }}>
							<Col xs={2}>
								<Row style={{ padding: '5px' }}>
									<Badge className='badgeLightGray' style={{ width: '100%' }}>
										Paso Nro. {index + 1}
									</Badge>
								</Row>
								<Row style={{ padding: '5px' }}>
									<Badge className='badgeGray' style={{ width: '100%' }}>
										{paso.tiempo} Min.
									</Badge>
								</Row>
								<Row
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-around',
									}}
								></Row>
							</Col>
							<Col xs={10}>
								<div
									style={{
										borderRadius: '5px',
										padding: '10px',
										color: 'gray',
										width: '100%',
										height: '100%',
										backgroundColor: 'lightgray',
										fontWeight: 'normal',
										textAlign: 'left',
										fontSize: 'medium',
									}}
								>
									{paso.explicativo}
								</div>
							</Col>
						</Row>
					</Row>
				))}
			</Container>
		)
	);
};

export default DetallesProductos;
