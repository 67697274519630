import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./_Recetas.scss";
import { Link } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirebaseApp } from "reactfire";
import { connect } from "react-redux";

function getPic(doc, firebase, setPic) {
	if (!doc) {
		return;
	}

	const data = doc.data();

	if (data.imagen == "") {
		setPic("https://via.placeholder.com/158x237/?text=The+cake+is+a+lie");
		return;
	}

	const ref = firebase.storage().refFromURL(data.imagen);

	ref
		.getDownloadURL()
		.then((url) => {
			setPic(url);
		})
		.catch((e) => {
			console.error("[getPic Error]", e);
			setPic("https://via.placeholder.com/158x237/?text=The+cake+is+a+lie");
		});
}

function Receta(props) {
	const firebase = useFirebaseApp();
	const [recetaPic, setPic] = useState();

	const [receta, loading_receta, error_receta] = useDocument(
		firebase
			.firestore()
			.collection("usuarios")
			.doc(props.usuario.id)
			.collection("establecimientos")
			.doc(props.usuario.establecimiento)
			.collection("categorias_recetas_base")
			.doc(props.id_categoria)
			.collection("recetas_base")
			.doc(props.id_receta)
	);

	useEffect(() => {
		getPic(receta, firebase, setPic);
	}, [receta]);

	return (
		!loading_receta && (
			<Card
				as={Link}
				to={`/panel-de-control/recetas-base/${props.id_categoria}/${props.id_receta}`}
				style={{ width: "10rem" }}
			>
				<Card.Img variant='top' src={recetaPic} />
				<Card.ImgOverlay>
					<Card.Title className='titleCardReceta'>
						{receta.data().nombre}
					</Card.Title>
				</Card.ImgOverlay>
			</Card>
		)
	);
}

const mapStateToProps = (state) => {
	return {
		usuario: state.user,
	};
};

export default connect(mapStateToProps)(Receta);
