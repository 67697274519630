// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyDtRPq4vqnTrdK3bysUE1BiQhEjbIEdsRw",
  authDomain: "sweet-3bfbf.firebaseapp.com",
  projectId: "sweet-3bfbf",
  storageBucket: "sweet-3bfbf.appspot.com",
  messagingSenderId: "165758871004",
  appId: "1:165758871004:web:dd43b93fa0ba38e85c5b19",
  measurementId: "G-29LJTH5PQY",
};

export default firebaseConfig;
