import AJAX from '../../../utils/axios';

export const register = async (params) => {
	console.log('params', params);

	try {
		let res = await AJAX.post('endpoint/usuarios/new', params);
		return res;
	} catch (error) {
		return error;
	}
};
