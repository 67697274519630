import { Formik } from "formik";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import LogoSVG from "../../assets/logo.svg";

import "./_ingreso-datos.scss";
import Button from "react-bootstrap/Button";
import { Col, Form, Row, Image } from "react-bootstrap";

import { useCollection } from "react-firebase-hooks/firestore";

import { useFirebaseApp } from "reactfire";

function IngresoEstablecimiento() {
  //Llamado a Firebase
  const firebase = useFirebaseApp();

  let user = firebase.auth().currentUser;

  let db = firebase.firestore().collection("usuarios").doc(user.uid);

  const [collection, loading, error] = useCollection(
    db.collection("establecimientos")
  );

  let [readyToGo, setReadyToGo] = useState(false);

  return (
    <div className="contenedorInicio container-xxl">
      {readyToGo && <Redirect exact to="/panel-de-control" />}
      <div className="card cardLogin">
        <img src={LogoSVG} className="logoFormLogin" />
        <div className="card-body">
          <Formik
            initialValues={{ establecimiento: "" }}
            validate={(values) => {
              //validar ingreso de contraseña
              const errors = {};
              if (
                !values.establecimiento &&
                values.establecimiento != "holder"
              ) {
                errors.establecimiento = "Requerido";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await db
                .update({ establecimiento: values.establecimiento })
                .then(() => {
                  console.log(
                    "nuevo establecimiento seleccionado: ",
                    values.establecimiento
                  );
                  setReadyToGo(true);
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <Row className="justify-content-md-center">
                    <p>
                      <b>ESTABLECIMIENTOS</b>
                    </p>
                    <p>
                      Seleccione el establecimiento con el que desea trabajar.
                    </p>
                  </Row>
                  <Row className="justify-content-md-center pt-3">
                    <Col sm={2}>
                      <Image src={LogoSVG} rounded />
                    </Col>
                    <Col sm={10}>
                      {error && (
                        <span>Ocurrió un error al buscar las opciones</span>
                      )}
                      {loading && <span>Cargando...</span>}
                      {collection && (
                        <Form.Control
                          className="inputLogin"
                          as="select"
                          name="establecimiento"
                          custom
                          value={values.establecimiento}
                          onChange={handleChange}
                          onBlur={handleChange}
                        >
                          <option value="holder">
                            Seleccione establecimiento
                          </option>

                          {collection.docs.map((doc) => {
                            let data = doc.data();
                            return (
                              <option value={doc.id} key={doc.id}>
                                {data.nombre}
                              </option>
                            );
                          })}
                        </Form.Control>
                      )}
                      <Form.Text>
                        {errors.establecimiento &&
                          touched.establecimiento &&
                          errors.establecimiento}
                      </Form.Text>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center pt-4 pl-2 pr-2">
                    <Button
                      block
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      SELECCIONAR
                    </Button>
                  </Row>
                  <Row className="justify-content-md-center pt-4 pl-2 pr-2">
                    <Button
                      block
                      variant="outline-primary"
                      as={Link}
                      to="/nuevo-establecimiento"
                    >
                      CREAR NUEVO
                    </Button>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default IngresoEstablecimiento;
